@import './variables.scss';

body{
  margin: 0;
  font-family: $font-text;
  color: $color_1;
  font-size: 14px;
  line-height: 100%;
}

div, section, ul{
  &::-webkit-scrollbar {
    width: 10px;
    height: 3px;
    padding-right: 4px;
  }
  &::-webkit-scrollbar-button {
     background-color: transparent;
	 height: 0px;
    }
  &::-webkit-scrollbar-track {
     background-color: transparent;
    }
  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    height: auto;
    height: 50px; 
    background-color: #56677D;
    border-radius: 1px;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  &::-webkit-resizer {
    background-color: #56677D;
  }
  scrollbar-width: thin;
  scrollbar-color: #56677D;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-base-color: #56677D;
  scrollbar-face-color: #56677D;
  scrollbar-3dlight-color: #56677D;
  scrollbar-highlight-color: #56677D;
  scrollbar-track-color: #56677D;
  scrollbar-arrow-color: black;
  scrollbar-shadow-color: #56677D;
  scrollbar-darkshadow-color: #56677D;
}

h1,h2,h3,h4,h5{
  margin: 0;
  font-family: $font-title;
  font-weight: 500;
  line-height: normal;
}

a{
  text-decoration: none;
  color: $color_1;
}

p{
  margin: 0;
}

ul,li{
  margin: 0;
  padding: 0;
}

.dftitle{
  font-family: $font-title;
}
.subtitle{
  font-family: $font-subtitle, serif;
}
.text{
  font-family: $font-text, serif;
  &--blue{
    color: $color-2;
  }
  &--lite{
    color: $color-3;
  }
}


.cognos{
  padding: 22px;
  height: 100%;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-sizing: border-box;
  & .monthly-stats {
    text-align: right;
  }
  & *{
    box-sizing: border-box;
  }
  &.national{
    .info{
      flex-direction: column;
      &__videos{
        margin-bottom: 20px;
        width: 30%;
      }
      &__news_video_nat{
        display: flex;
        flex-direction: row;
      }
      &__country-totals_video_all{
        display: flex;
        flex-direction: column;
        .info__country-totals{
          margin-bottom: 20px;
        }
      }
      &__trends{
        margin-right: 20px;
        width: calc(70% - 20px);
      }
    }
  }
  &.international{
    .info{
      align-items: stretch;
      justify-content: space-between;
      &__country-totals{
        width: calc((100% - 20px) / 2);
        flex-grow: 0;
      }
      &__news, &__videos{
          max-height: 100%;
          flex-grow: 0;
      }
      &__news{
        margin-bottom: 20px;
        height: calc(100vh - 312px - 20px);
        min-height: 140px;
      }
      &__videos{
        height: calc(30vh - 20px);
        max-height: 312px;
      }
      &__news_video_int{
        display: flex;
        flex-direction: column;
        width: calc((100% - 20px) / 2);
      }
    }
    .table{
      &__header{
        .first{
          flex-grow: 1;
        }
        .monthly-stats{
          flex-basis: 14%;
          @media (max-width: 1400px) {
            flex-basis: 15%;
          }
        }
      }
      &__footer{
        .first{
          flex-grow: 1;
        }
        .monthly-stats{
          flex-basis: 14%;
          @media (max-width: 1400px) {
            flex-basis: 15%;
          }
        }
      }
      &__item{
        .first{
          flex-grow: 1;
        }
        .monthly-stats{
          flex-basis: 14%;
          @media (max-width: 1400px) {
            flex-basis: 15%;
          }
        }
      }
    }
  }
}

.info{
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  height: 100%;
  max-height: calc(100vh - 157px);
  overflow: hidden;
  width: 100%;
  min-width: 100%;
  &__container{
    display: flex;
    align-items: stretch;
    flex-direction: column;
    margin-right: 20px;
    width: 50%;
  }
  &__trends{
    border: 1px solid #CCCCCC;
    border-radius: 3px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
  &__news, &__videos{
    border: 1px solid #CCCCCC;
    border-radius: 3px;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    align-items: stretch;
  }
  &__news{
    flex-grow: 1;
    flex-shrink: 3;
  }
  &__videos{
    flex-grow: 1;
    flex-shrink: 2;
    max-height: 404px;
  }
  &__country-totals{
    border: 1px solid #CCCCCC;
    border-radius: 3px;
    box-sizing: border-box;
    flex-grow: 1;
  }
  &__country-totals_video_all{
    display: flex;
    flex-direction: column;
    width: 50%;
    .info__country-totals{
      margin-bottom: 20px;
    }
  }
  
  .tooltipik{
    width: 19px;
    height: 19px;
    border-radius: 50%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    position: relative;
    left: 2px;
    svg.info{
      width: 2px;
      height: 100%;
    }
  }
}


.react-tooltip-lite{
  background: $color_3;
  border-radius: 3px;
  color: #fff;
}
.react-tooltip-lite-arrow{
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom-width: 12px;
  border-bottom-style: solid;
  border-color: $color_3;
}