@import '../../../scss/variables.scss';

.toggle{
  display: flex;
  align-items: stretch;
  box-sizing: border-box;
  height: 27px;
  cursor: pointer;
  .button{
    outline: none;
    border: 2px solid $color_3;
    background: #FFFFFF;
    width: 55px;
    padding: 0;
    font-family: $font-subtitle;
    color: $color_2;
    transition: all 0.15s ease;
    font-size: 14px;
    letter-spacing: 0.2px;
    cursor: pointer;
    &:first-child{ 
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-right: 0!important;
    }
    &:last-child{
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-left: 0!important;
    }
    &.active{
      border: 2px solid $color_2;
      background: $color_2;
      color: #FFFFFF;
    }
  }
}