@import '../../../scss/variables.scss';
.news{
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  &__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 25px 20px 20px;
    .news__title{
      letter-spacing: 1px;
      color: #1e3553;
      margin: 0;
      font-size: 22px;
      font-weight: 500;
    }
  }
  .toggle{
    & .button{
      width: 110px;
    }
  }
  &__list{
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    overflow-y: auto;
    padding: 0 5px 0 20px;
    margin-right: 5px;
    a{
      &:hover{
        transition: all 0.15s ease;
        box-shadow: 0px 5px 10px -3px rgba($color_2, 0.7);
        text-decoration: none;
      }
      text-decoration: none;
      color: #1e3553;
      font-size: 14px;
    }
  }
  &__date{
    color: $color_3;
  }
  &__item{
    background: $color_4;
    padding: 20px;
    margin-bottom: 10px;
    margin-right: 10px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .news{
      &__title{
        font-size: 16px;
        font-family: $font-subtitle;
        margin-bottom: 10px;
        width: 100%;
        font-weight: normal;
      }
      &__time{
        width: 100%;
      }
    }
    width: calc((100% - 50px * 2) / 2);
    min-width: calc((100% - 50px * 2) / 2);
    &:nth-child(7n - 2),
    &:nth-child(7n - 1),
    &:nth-child(7n - 0){
      width: calc((100% - 50px * 3) / 3);
      min-width: calc((100% - 50px * 3) / 3);
    }
    &:nth-last-child(1){
      flex-grow: 1;
    }
    &:nth-last-child(2){
      flex-grow: 1;
    }
    &:nth-last-child(3){
      flex-grow: 1;
    }
    @media (max-width: 1200px) {
      &:nth-child(7n - 2),
      &:nth-child(7n - 1),
      &:nth-child(7n - 0){
        width: calc((100% - 50px * 2) / 2);
        min-width: calc((100% - 50px * 2) / 2);
      }
    }
    @media (max-width: 576px) {
      &:nth-child(7n - 2),
      &:nth-child(7n - 1),
      &:nth-child(7n - 0){
        width: calc((100% - 50px));
        min-width: calc((100% - 50px ));
      }
      width: calc((100% - 50px));
      min-width: calc((100% - 50px ));
    }
  }
}

div, section, ul{
  &::-webkit-scrollbar {
    width: 10px;
    height: 3px;
    padding-right: 4px;
  }
  &::-webkit-scrollbar-button {
    background-color: transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    height: 50px;
    background-color: #56677D;
    border-radius: 1px;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  &::-webkit-resizer {
    background-color: #56677D;
  }
  scrollbar-width: thin;
  scrollbar-color: #56677D;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-base-color: #56677D;
  scrollbar-face-color: #56677D;
  scrollbar-3dlight-color: #56677D;
  scrollbar-highlight-color: #56677D;
  scrollbar-track-color: #56677D;
  scrollbar-arrow-color: black;
  scrollbar-shadow-color: #56677D;
  scrollbar-darkshadow-color: #56677D;
}