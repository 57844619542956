.df-login-sidebar-teaser-visual, .df-c11-login-sidebar-analysis-visual {
	width: 200px;
}

.df-button--with-caret {
	background-color: white;
	color: rgb(30, 53, 83);
	color: rgb(30, 53, 83);
	width: 240px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	font: bold 0.875rem GothamPro;
	text-transform: uppercase;
	cursor: pointer;
	text-decoration: none;
	border: 2px solid rgb(30, 53, 83);


}

.df-login-sidebar-teaser {

}

.df-login-sidebar-analysis {

}

.teaser-element {
	border: 1px solid #CCCCCC;
	border-radius: 3px;
	box-sizing: border-box;
	margin-bottom: 20px;
	padding: 20px;
}

.df-login-sidebar-teaser-title {
	color: #1e3553;
	margin: 0;
	font-size: 22px;
	font-weight: 500;
}

.df-login-sidebar-body-text-button {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	line-height: normal;
	& p {
		margin-bottom: 14px;
		margin-top: 14px;
	}
}

.df-login-sidebar-teaser-visual-container {
	padding: 14px;
}

.df-login-sidebar-teaser-visual-container, .df-login-sidebar-body {
	display: flex;
	justify-content: space-between;
}

@media (max-width: 1200px) {
	.df-login-sidebar-teaser-visual-container, .df-login-sidebar-body {
		flex-direction: column-reverse;
	}
}