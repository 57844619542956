.login {
    display: flex;
    max-height: 100%;
    overflow: hidden;
}
.login__background {
    z-index: -100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.login__container {
    background-color: #EFEEEF;
    padding: 30px 40px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60vw;
    height: calc(100vh - 60px);
    color: #1e3553;
    overflow: hidden;
}
.login__container_full {
    background-color: #EFEEEF;
    padding: 30px 40px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
    height: calc(100vh - 60px);
    color: #1e3553;
    overflow: hidden;
}
.login__header {
    display: flex;
    justify-content: center;
}
.login__logo-img {
    width: 100%;
}
.login__product-image {
    padding-bottom: 30px;
    height: auto;
    width: auto;
    max-width: 80%;
    max-height: 80%;
}
.login__product-image_access_denied {
    padding-bottom: 30px;
    height: auto;
    width: auto;
    max-width: 80%;
    max-height: 50%;
}
.login__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100% - 320px);
    width: 100%;
    justify-content: center;
}
.login__greeting {
    color: #1e3553;
    font: lighter 2.5rem GothamPro, serif;
    line-height: 1.2;
    letter-spacing: -1px;
    text-transform: uppercase;
    text-align: center;
    max-width: 600px;
}
.login__greeting_bold {
    font-weight: bold;
    font-family: GothamPro, serif;
    color: #1e3553;
}
.login__greeting_small {
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: GothamPro, serif;
    font-size: 1.5rem;
    text-transform: none;
}
.login__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
}
.login__footer-left-side {
    display: flex;
    justify-content: space-between;
}
.login__gray_item {
    font: .8125rem GothamPro, serif;
    color: #1e3553;
    text-decoration: none;
    margin-bottom: 0;
    margin-top: 0;
}
.login__gray_item:not(:last-child) {
    margin-right: 20px;
}

a.login__gray_item:hover, a.login__gray_item:active, a.login__gray_item:focus  {
    text-decoration: none;
    color: #23527c;
}
.login__footer-middle {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 60px;
}
.login__footer-right-side {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 60px;
}
.login__footer-item {
    width: 300px;
}
.login__cursor-pointer {
    cursor: pointer;
    display: flex;
}
.login__language-container {
    display: flex;
    flex-direction: column-reverse;
    height: 15px;
    position: relative;
    margin-right: 10px;
}
.login__language, .login__language-other, .login__language-other_active, .login__contact {
    font: .8125rem GothamPro, serif;
    color: #1e3553;
    text-align: right;
}
.login__contact {
    text-align: right;
}
.contact-large a {
    font: 1.5rem GothamPro, serif;
    text-align: right;
    text-decoration: none;
}
.login__language-other, .login__language-other_active {
    position: relative;
    left: 0;
    visibility: hidden;
    transition: .2s;
    color: transparent;
}
.login__language-other_active {
    visibility: visible;
    color: #1e3553;
}
.login__lang-picker-triangle, .login__lang-picker-triangle_active {
    width: 0; 
    height: 0;
    position: relative;
    border-top: none;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 8px solid #1e3553;
    align-self: center;
    transition: .2s;
}
.login__lang-picker-triangle_active {
    border-bottom: none;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 8px solid #1e3553;
}

.login__button {
    padding-bottom: 20px;
}


.sidebar__container {
    display: flex;
    width: 40vw;
    margin: 22px;
    flex-direction: column;
    height: calc(100vh - 45px);
}

.teaser__container {
    display: flex;
    flex-direction: column;
}

.news__container {
    border-radius: 3px;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    align-items: stretch;
}

.LoaderButton {
    background-color: #1e3553;
    color: white;
}

@media all and (min-width: 480px) {

    .login__button {
        padding-top: 60px;
        padding-bottom: 25px;
        margin: 0 auto;
        max-width: 320px;
    }
}

@media screen and (max-width: 960px){
    .login__greeting {
        width: 90%;
        font-size: 2.5rem;
        margin-bottom: 30px;
    }
    .login {
        flex-direction: column;
    }

    .login__container, .login__container_full {
        width: calc(100% - 80px);
    }

    .sidebar__container {
        width: calc(100% - 44px);
    }

}

@media screen and (max-width: 820px){
    .login__product-image {

    }
}

@media screen and (max-height: 1150px){
    .login__greeting {
        font-size: 2rem;
    }
}
@media screen and (max-height: 1024px){
    .login__button {
        padding-top: 0px;
    }
    .login__greeting {
        font-size: 1.5rem;
    }
}

@media screen and (max-height: 500px){


}

@media screen and (max-width: 480px){
    .login__greeting {
        font-size: 1.5rem;
    }
}

@media screen and (max-height: 380px) {
    .login__button {
        padding: 0px 0;
    }

    .login__greeting {
        margin-bottom: 10px;
    }
}

@media screen and (max-height: 260px){
    .login__greeting {
        font-size: 1.3rem;
    }
}
