//site color palette
$color_1: #1e3553;
$color_2: #1e3553;
$color_3: #7494BD;
$color_4: #E8EEF6;
$color_5: #F3F3F3;
$color_6: #CCCCCC;
$color_7:  #FF0000;
$color_8:  #008119;

// site fonts
$font-title: 'Helvetica Neue for IBM',Helvetica,Arial,sans-serif;
$font-subtitle: 'Helvetica Neue for IBM','Helvetica-Medium', sans-serif;
$font-text: 'Helvetica Neue for IBM','Helvetica-Regular', sans-serif;